<template>
  <div class="container-fluid mt-3">
    <Form @submit="addAds" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'الوحدات الإعلانية' , path: '/ads'}" current_page="تعديل الإعلان"/>
        <div class="controls-btns d-flex gap-2 mb-2">
          <button class="btn btn-publish" type="submit" @click="addAds" :disabled="saving">تعديل</button>
        </div>
      </div>

      <el-card class="custom-card" >
        <el-tabs v-model="activeTab">
          <el-tab-pane label="تفاصيل الإعلان" name="adsDetails">

            <div  v-if="!loading" class="row my-3">
              <div class="col-lg-10">
                <div class="d-flex flex-wrap gap-3">

                  <reusable-field inputKey="name" label-text="اسم الاعلان" @update="handleOnChange"
                                  :errors="errors" error-message="الرجاء إدخال اسم الاعلان" :prev_val="ads.name"
                  />

                  <reusable-field inputKey="provider" label-text="المزود" @update="handleOnChange"
                                  :errors="errors" error-message="الرجاء إدخال اسم مزود الإعلان" :prev_val="ads.provider"
                  />

                  <reusable-field inputKey="type" label-text="نوع الإعلان" @update="handleOnChange"
                                  :errors="errors" error-message="الرجاء إدخال نوع الإعلان" :prev_val="ads.type"
                  />

                  <div class="form-group">
                    <label for="places">المساحات الاعلانية المتوفرة</label>
                    <select id="places" class="form-select form-control-solid" v-model="ads.place_id">
                      <!--                      <optgroup label="اماكن الاعلانية للويب" v-if="places.length">-->
                      <template :key="index" v-for="(place,index) in places">
                        <option :value="p.id" :key="p.id" v-for="p in place" >{{ p.name }}</option>
                      </template>
                      <!--                      </optgroup>-->
                      <!--                      <optgroup label="اماكن الاعلانية للموبايل" v-if="places.length">-->
                      <!--                        <option :value="place.id" v-for="place in places.mobile">{{ place.name }}</option>-->
                      <!--                      </optgroup>-->
                    </select>
                  </div>

                  <reusable-field inputKey="image" label-text="رابط صورة الاعلان" @update="handleOnChange"
                                  :errors="errors" error-message="الرجاء إدخال رابط صورة الاعلان" :prev_val="ads.image"
                  />

                  <reusable-field inputKey="url" label-text="رابط صفحة الاعلان" @update="handleOnChange"
                                  :errors="errors" error-message="الرجاء إدخال رابط صفحة الاعلان" :prev_val="ads.url"
                  />
                </div>
              </div>
            </div>
            <loader v-else/>
          </el-tab-pane>
        </el-tabs>

      </el-card>
    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {Field, Form} from "vee-validate";
import ReusableField from "@/components/formControl/reusableField";
import { getFreePlaces} from "@/services/ads";
import {editAds, getSingleAds} from "../../services/ads";
import Loader from "../../components/Loader";

export default {
  name      : "edit Ads",
  components: {
    Loader,
    Breadcrumb,
    Form,
    Field,
    ReusableField
  },
  data()
  {
    return {
      saving  : false,
      loading  : true,
      activeTab: 'adsDetails',
      places   : [],
      ads      : {
        name    : '',
        provider: '',
        type    : '',
        place_id: '',
        url     : '',
        image   : '',
        active  : true
      }
    }
  },
  mounted() {
    this.fetchPlaces();
    this.getPlace();
  },
  methods: {
    async getPlace()
    {
      const result = await getSingleAds(this.$route.params.id)
      console.log(result.data)
      this.loading = false
      this.ads = result.data
    },
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
    handleOnChange(key, val)
    {
      this.ads = {
        ...this.ads,
        [key]: val
      }
    },
    fetchPlaces()
    {
      getFreePlaces()
          .then(res => {
            this.places = res.data
          })
    },
    addAds()
    {
      this.saving = true
      editAds(this.$route.params.id,this.ads)
          .then(res=> {
            this.saving = false
            console.log(res)
            this.$router.push('/ads')
          })
          .catch((error)=>{
            this.saving = false
            console.log(error)
          })
    }
  }
}

</script>

<style>
.form-control,
select.form-select {
  width: 445px;
  max-width: 100%;
}
</style>
