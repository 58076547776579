import request from "@/utils/request";

export function getAds(){
  return request(
    {
      url: "ads",
      method: "get"
    }
  )
}


export function editAds(ads_id,data){
  return request(
    {
      url: `ads/${ads_id}`,
      method: 'put',
      data
    }
  )
}
export function getFreePlaces() {
  return request(
    {
      url: 'places/free-places',
      method: "get"
    }
  )
}

export function createAds(data) {
  return request(
    {
      url: "ads",
      method: "post",
      data
    }
  )
}

export function getPlaces() {
  return request(
    {
      url: "places",
      method: "get"
    }
  )
}

export function createPlace(data) {
  return request(
    {
      url: "places/store",
      method: "post",
      data
    }
  )
}

export function getSingleAds(ads_id) {
  return request (
    {
      url: `ads/${ads_id}`,
      method: "get"
    }
  )
}

export function getPlace(place_id) {
  return request(
    {
      url: `places/${place_id}`,
      method: "get"
    }
  )
}


export function editPlace(place_id,data){
  return request(
    {
      url: `places/${place_id}`,
      method: 'put',
      data
    }
  )
}
